import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import { ReactComponent as SvgIconArrow } from 'Static/assets/ui/arrow.svg';
import { ReactComponent as SvgIconArrowPrevNext } from 'Static/assets/ui/arrow-next-prev.svg';
import { ReactComponent as SvgIconCheckboxBlank } from 'Static/assets/ui/checkbox-blank.svg';
import { ReactComponent as SvgIconCheckboxChecked } from 'Static/assets/ui/checkbox-checked.svg';
import { ReactComponent as SvgIconChevron } from 'Static/assets/ui/chevron.svg';
import { ReactComponent as SvgIconMenu } from 'Static/assets/ui/menu.svg';
import { ReactComponent as SvgIconPlus } from 'Static/assets/ui/plus.svg';
import { ReactComponent as SvgIconDownload } from 'Static/assets/ui/download.svg';
import { ReactComponent as SvgIconPlusSmall } from 'Static/assets/ui/plus-small.svg';
import { ReactComponent as SvgIconCheck } from 'Static/assets/ui/check.svg';

import './Icon.scss';

const useIcon = (string) => {
  switch (string) {
    case "arrow":
      return <SvgIconArrow />

    case "arrowPrevNext":
        return <SvgIconArrowPrevNext />

    case "checkboxBlank":
      return <SvgIconCheckboxBlank />

    case "checkboxChecked":
      return <SvgIconCheckboxChecked />

    case "chevron":
      return <SvgIconChevron />

    case "menu":
      return <SvgIconMenu />

    case "plus":
      return <SvgIconPlus />

    case "plus-small":
      return <SvgIconPlusSmall />

    case "check":
      return <SvgIconCheck />
      
    case "download":
      return <SvgIconDownload />

      default:
      break;
  }
  return false;
}

const Icon = (props) => {

  const { iconType, rotation, classes, onClick } = props;

  return (
    <div
      className={classNames("icon", classes, {
        "rotate-45deg": rotation === "45deg",
        "rotate-90deg": rotation === "90deg",
        "rotate-180deg": rotation === "180deg",
        "rotate-270deg": rotation === "270deg"
      })}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      role="button"
    >
      {useIcon(iconType)}
    </div>
  );
}

Icon.propTypes = {
  iconType: PropTypes.string.isRequired,
  rotation: PropTypes.string,
  classes: PropTypes.string,
  onClick: PropTypes.func
};

export default Icon