/* eslint-disable no-unused-vars */
import React from 'react'
// import TransitionLink from 'gatsby-plugin-transition-link'
import {AniLink} from "gatsby-plugin-transition-link";

const PageTransitionLink = props => {
  const {
    to,
    children,
    classes
  } = props;
  // console.log("classes", classes)

  return (
    <AniLink
      cover 
      to={to}
      bg="#000000"
      direction="top"
      className={classes}
      // paintDrip
      // color="#000000"
      // duration={1}
    >
      {children}
    </AniLink>
  )
  // return (
  //   <TransitionLink
  //     className={classes}
  //     to={to}
  //     exit={{ length: 0.5 }}
  //     entry={{ delay: 0.5, length: 1 }}
  //   >
  //     {children}
  //   </TransitionLink>
  // )
}

export default PageTransitionLink


